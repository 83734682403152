"use client";
import Image from "next/image";

const Getintouch = () => {
  return (
    <div>
      <section className=" bg-white py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-start justify-between">
            <div>
              <h2 className="font-bold text-color-blue-600 my-3">
                Get In <span className="text-red-600">Touch</span>
              </h2>
              <form action="#" className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    {/* <label for="first_name" className="block mb-2 text-sm font-medium text-gray-300 dark:text-white">First name</label> */}
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-400 text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div>
                    {/* <label for="email" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-300">Your email</label> */}
                    <input
                      type="email"
                      id="email"
                      className="shadow-sm bg-gray-50 border border-gray-400 text-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-3.5"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div>
                    {/* <label for="phone" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-300">Phone Number</label> */}
                    <input
                      type="text"
                      id="phone"
                      className="block p-3.5 w-full text-sm text-gray-300 bg-gray-50 rounded-lg border border-gray-400 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                      placeholder="Mobile Number"
                      required
                    />
                  </div>
                  <div>
                    {/* <label for="subject" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-300">Subject</label> */}
                    <input
                      type="text"
                      id="subject"
                      className="block p-3.5 w-full text-sm text-gray-300 bg-gray-50 rounded-lg border border-gray-400 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                      placeholder="Company Name"
                      required
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  {/* <label for="message" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-400">Your message</label> */}
                  <textarea
                    id="message"
                    rows="6"
                    className="block p-2.5 w-full text-sm text-gray-300 bg-gray-50 rounded-lg shadow-sm border border-gray-400 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Leave a comment..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="py-3 px-5 text-sm font-medium text-center text-white rounded-md bg-red-600 uppercase sm:w-fit hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300"
                >
                  SUBMIT
                </button>
              </form>
            </div>
            <div>
              <Image
                alt="Handshake"
                src="/images/handshake-1.jpg"
                className=" object-cover rounded-lg lg:rounded-xl"
                width={680}
                height={453}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Getintouch;
