"use client";
import { CheckIcon } from "@heroicons/react/solid"; // For solid icons, or use "/outline" for outlined icons

const Ourusp = () => {
  return (
    <div>
      <section className="bg-color-blue-500 py-10 md:py-16 lg:py-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-12">
            <h2 className="font-semibold text-white my-3">OUR USP</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 text-white">
            {[
              "Provides boutique solution with personalized services.",
              "We offer services 24×7 365 days of the year.",
              "Our global alliances with 80 countries and minimum operating costs help us to be most competitive.",
              "Our global alliances also help us to get high volume deals with airlines. We share these airline discounts with our customers.",
              "Also offers volume discounts along with downsell savings.",
              "We save for you by charging service fees per passenger and not per ticket.",
              "No service fees are charged for cancelled tickets and reissued tickets.",
              "No additional costs are charged for services provided After Office Hours (AOH).",
              "We cater to all nationalities of passengers irrespective of their location.",
              "We provide customised MIS Reports for review of business.",
              "Our teams are trained to cater during emergency situations.",
              "Caters to crisis situations differently and skillfully, understanding that every minute counts in crisis times.",
              "Offers Dedicated Bandwidth to our clients which is available round the clock.",
              "Provides Escalation Matrix including Dedicated Account Manager who liaises with customer for all types of coordination and escalations.",
              "We are a one-stop solution for Air Tickets, Visas, Insurance, Hotel, MICE, Holidays, Ok to Board, Passport, Transport, and other travel-related services.",
            ].map((item, index) => (
              <div
                key={index}
                className="flex flex-row border border-white p-4 md:p-6 items-start min-h-[200px]"
              >
                <div className="bg-red-600 self-start mr-4 mt-3 p-2">
                  <CheckIcon className="h-5 w-5 text-white" />
                </div>
                <p className="mt-2 text-lg">{item}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ourusp;
