"use client";
import Image from "next/image";

const Awards = () => {
  return (
    <div className="relative py-20 md:py-20 lg:py-96 -z-20 w-full">
      <div className="absolute inset-0">
        <Image
          src="/images/certificate2024.jpg" // Replace with your actual image path
          alt="Awards And Achievements"
          fill
          quality={75}
          className="-z-10 object-contain"
        />
      </div>
    </div>
  );
};

export default Awards;
