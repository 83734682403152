"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Intro = () => {
  return (
    <div>
      <section className="py-10 md:py-20 lg:py-16">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12 lg:mb-16">
            <div className="lg:pr-6">
              <h2 className="font-semibold text-color-blue-500 my-3">
                About Us
              </h2>
              <p className=" text-lg">
                One of the leading travel management company with excellent
                track record and consistent growth. We provide end to end travel
                logistic solutions. Accredited by IATA and ISO certification,
                professionally organises and manages travel related services
                globally 365 days 24/7 nonstop.
              </p>
              <p className=" text-lg">
                Having access to 80 countries local fares and 900 airlines of
                the globe, we are humbly labelled amongst the top 10 travel
                companies internationally. Our key features are transparency,
                customization, efficiency and quality service.
              </p>
              <div className="mt-6 md:mt-8">
                <a
                  href="/about-us"
                  className="btn-red px-6 py-2 lg:px-5 lg:py-2 text-sm"
                >
                  Read More
                </a>
              </div>
            </div>
            <div>
              <Image
                alt="About Us - Team of Excellence"
                src="/images/team-of-excellence.jpg"
                className="object-cover"
                width={680}
                height={453}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-top justify-center text-center">
            <div>
              <div className="h-28 overflow-hidden">
                <Image
                  alt=""
                  src="/images/ico_vision.png"
                  className=" object-cover m-auto"
                  width={110}
                  height={110}
                />
              </div>
              <h4 className="font-sans font-bold text-black my-4">
                Our Vision
              </h4>
              <p>
                To become one of the leading service provider and building
                strong credibility amongst our stake holders
              </p>
            </div>
            <div>
              <div className="h-28 overflow-hidden">
                <Image
                  alt=""
                  src="/images/ico_mission.png"
                  className=" object-cover m-auto"
                  width={110}
                  height={110}
                />
              </div>
              <h4 className="font-sans font-bold text-black my-4">
                Our Mission
              </h4>
              <p>
                Mission of is to build internal and external credibility by
                combining human values, transparency and seamless approach
              </p>
            </div>
            <div>
              <div className="h-28 overflow-hidden">
                <Image
                  alt=""
                  src="/images/ico_value.png"
                  className=" object-cover m-auto"
                  width={110}
                  height={110}
                />
              </div>
              <h4 className="font-sans font-bold text-black my-4">
                Our Values
              </h4>
              <p>
                Ensuring transparency and providing excellent value for money to
                our offerings
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
