"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Partners = () => {
  return (
    <div>
      <section className="bg-white py-0">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 gap-3 items-center justify-between">
            <div>
              <Image
                alt=""
                src="/images/accreditation.png"
                className=" object-cover m-auto"
                width={273}
                height={273}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
