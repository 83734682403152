"use client";
import { useRef, useState, useEffect } from "react";
import Image from "next/image";
import Slider from "react-slick";
import { motion } from "framer-motion"; // Import Framer Motion
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"; // Import icons
import Link from "next/link";

const SlickSlider = () => {
  const mainSliderRef = useRef(null);
  const thumbSliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const allThumbnails = [
    { src: "/images/fhm-travels-1-min.jpg", alt: "Thumbnail 1" },
    { src: "/images/fhm-travels-2-min.jpg", alt: "Thumbnail 2" },
    { src: "/images/Insurance-Services-1.jpg", alt: "Thumbnail 2" },
    { src: "/images/fhm-travels-4-min.jpg", alt: "Thumbnail 3" },
    { src: "/images/fhm-travels-5-min.jpg", alt: "Thumbnail 4" },
  ];

  const allSmallThumbnails = [
    { src: "/images/fhm-travels-1-min.jpg", alt: "Thumbnail 2" },
    { src: "/images/fhm-travels-2-min.jpg", alt: "Thumbnail 3" },
    { src: "/images/Insurance-Services-1.jpg", alt: "Thumbnail 2" },
    { src: "/images/fhm-travels-4-min.jpg", alt: "Thumbnail 4" },
    { src: "/images/fhm-travels-5-min.jpg", alt: "Thumbnail 1" },
  ];

  useEffect(() => {
    if (mainSliderRef.current && thumbSliderRef.current) {
      mainSliderRef.current.slickGoTo(currentSlide);
      thumbSliderRef.current.slickGoTo(currentSlide);
    }
  }, [currentSlide]);

  const mainSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true, // Enable arrows for main slider
    fade: true,
    nextArrow: <NextArrow />, // Custom arrow
    prevArrow: <PrevArrow />, // Custom arrow
    beforeChange: (_, newIndex) => {
      setCurrentSlide(newIndex); // Sync current slide
    },
    asNavFor: thumbSliderRef.current, // Sync with thumbnail slider
  };

  const thumbnailSliderSettings = {
    slidesToShow: 2, // Show 2 thumbnails at a time
    slidesToScroll: 1,
    asNavFor: mainSliderRef.current, // Sync with main slider
    focusOnSelect: true,
    arrows: false, // Disable arrows for the smaller thumbnails
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000, // Keep the same speed as the main slider
    initialSlide: 1, // Start from the second slide
  };

  const slides = [
    {
      title: "Air Ticket Services",
      content:
        "We offer competitive Air Fares for marine as well as corporate and leisure travel with Global alliances in 80 countries.",
      src: "/images/fhm-travels-1-min.jpg", // Replace with your actual image path
      link: "#about-us",
      description: "Read More",
    },
    {
      title: "Visa Services",
      content:
        "Prepares the passport and visa application cases with highest accuracy.",
      src: "/images/fhm-travels-2-min.jpg", // Replace with your actual image path
      link: "#about-us",
      description: "Read More",
    },
    {
      title: "Insurance Services",
      content:
        "Tie ups to provide Travel Insurance as well as Health Insurance.",
      src: "/images/Insurance-Services-1.jpg", // Replace with your actual image path
      link: "#about-us",
      description: "Read More",
    },
    {
      title: "Hotel Services",
      content: "Global hotel tie ups to help us offer competitive rates.",
      src: "/images/fhm-travels-4-min.jpg", // Replace with your actual image path
      link: "#about-us",
      description: "Read More",
    },
    {
      title: "Marine Air Travel Simplified",
      content:
        "“Efficient and hassle-free marine travel solutions tailored to the shipping industry’s needs our go-to partner for seamless crew transfers and expert travel coordination.”",
      src: "/images/fhm-travels-5-min.jpg", // Replace with your actual image path
      link: "#about-us",
      description: "Read More",
    },
  ];

  return (
    <div className="relative">
      {/* Main Slider */}
      <div className="relative h-[640px] overflow-hidden">
        <Slider
          {...mainSliderSettings}
          ref={mainSliderRef} // Reference for syncing with thumbnail slider
        >
          {allThumbnails.map((thumbnail, index) => (
            <div key={index} className="relative h-[650px]">
              <Image
                src={thumbnail.src}
                alt={thumbnail.alt}
                fill
                className="object-cover z-[-1]"
              />

              {/* Dynamic slide text and link */}
              <motion.div
                className="absolute bottom-96 lg:bottom-52 left-5 lg:left-20 max-w-[30rem] uppercase"
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut" }} // Smooth transition for text and button
              >
                <h2 className="text-2xl lg:text-5xl font-normal text-white mb-4 font-serif">
                  {slides[index].title}
                </h2>
                <div className="text-base mt-4 font-sans text-white mb-10">
                  {slides[index].content}
                </div>
                <Link
                  href={slides[index].link}
                  className="bg-red-600 text-white px-5 py-2 lg:px-12 lg:py-4 rounded-none hover:bg-red-700"
                >
                  {slides[index].description}
                </Link>
              </motion.div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Absolute Thumbnail Slider (center-right position) */}
      <div className="absolute top-[70%] right-[15%] transform -translate-y-[50%] w-[500px]">
        <Slider
          {...thumbnailSliderSettings}
          ref={thumbSliderRef} // Reference for syncing with main slider
        >
          {allSmallThumbnails.map((thumbnail, index) => (
            <div key={index} className="p-2">
              <div className="w-[200px] h-[300px] relative">
                <Image
                  src={thumbnail.src}
                  alt={thumbnail.alt}
                  fill
                  className="object-cover rounded-3xl border-2 border-gray-800/40"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Centered Custom Arrow Buttons */}
      <div className="absolute bottom-[5%] left-1/2 transform -translate-x-1/2 flex justify-center space-x-4">
        <button
          onClick={() => mainSliderRef.current.slickPrev()}
          className="bg-gray-400 text-black p-4 rounded-full"
        >
          <FiArrowLeft size={32} /> {/* Left arrow icon */}
        </button>
        <button
          onClick={() => mainSliderRef.current.slickNext()}
          className="bg-gray-400 text-black p-4 rounded-full"
        >
          <FiArrowRight size={32} /> {/* Right arrow icon */}
        </button>
      </div>
    </div>
  );
};

// Custom Next Arrow
const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full cursor-pointer z-10"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-6 w-6 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full cursor-pointer z-10"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-6 w-6 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 5l-7 7 7 7"
        />
      </svg>
    </div>
  );
};

export default SlickSlider;
