"use client";
import Image from "next/image";
import { Avatar, Blockquote } from "flowbite-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 slides on desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative bg-cover bg-center py-8 md:py-16 lg:py-24">
      {/* Background Image */}
      <div className="absolute inset-0 -z-10">
        <Image
          src="/images/testimonials_bg.jpg"
          alt="Testimonials Background"
          fill
          className="object-cover"
          quality={100}
        />
        {/* Background Overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="mb-6 md:mb-10 lg:mb-16 text-center">
        <h2 className="font-bold text-white my-3 text-5xl">
          What <span className="text-red-600">Client Say?</span>
        </h2>
      </div>

      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        {/* Slick Slider Integration */}
        <Slider {...settings} className="text-white">
          <div className="flex testimonial flex-col h-full bg-opacity-70 bg-blue-900 border border-gray-300 shadow-xl p-6 md:p-8 rounded-lg lg:ml-6">
            <figure className="flex flex-col h-full justify-between">
              <Blockquote className="text-white">
                <svg
                  className="mb-4 h-8 w-8 text-red-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 14"
                >
                  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                </svg>
                <p className="text-base md:text-lg font-medium italic">
                  “Our crew changes have been handled in the area of Panama, Sao
                  Paulo, Lome Aruba with 100% success within the time frame and
                  budget that we expected. We highly recommend this company to
                  other technical marine departments…”
                </p>
              </Blockquote>
              <figcaption className="flex items-center justify-center mt-4">
                <Avatar
                  rounded
                  className="w-16 h-16 rounded-full self-start mr-4"
                  img="/images/user1.jpg"
                  alt="Thome Group"
                />
                <div className="text-left">
                  <div className="font-bold font-serif text-xl">
                    Thome Group
                  </div>
                  <p className="text-sm">Technical Superintendent</p>
                </div>
              </figcaption>
            </figure>
          </div>

          <div className="flex testimonial flex-col h-full bg-opacity-70 bg-blue-900 border border-gray-300 shadow-xl p-6 md:p-8 rounded-lg lg:ml-6">
            <figure className="flex flex-col h-full justify-between">
              <Blockquote className="text-white">
                <svg
                  className="mb-4 h-8 w-8 text-red-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 14"
                >
                  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                </svg>
                <p className="text-base md:text-lg font-medium italic">
                  “We are very happy to work with FHM Travels Pvt. Ltd. travel.
                  Invoices and SOA are raised on time and as per our requirement
                  with high accuracy.”
                </p>
              </Blockquote>
              <figcaption className="flex items-center justify-center mt-4">
                <Avatar
                  rounded
                  className="w-16 h-16 rounded-full self-start mr-4"
                  img="/images/user2.jpg"
                  alt="Symphony"
                />
                <div className="text-left">
                  <div className="font-bold font-serif text-xl">Symphony</div>
                  <p className="text-sm">Accountant</p>
                </div>
              </figcaption>
            </figure>
          </div>

          <div className="flex testimonial flex-col h-full bg-opacity-70 bg-blue-900 border border-gray-300 shadow-xl p-6 md:p-8 rounded-lg lg:ml-6">
            <figure className="flex flex-col h-full justify-between">
              <Blockquote className="text-white">
                <svg
                  className="mb-4 h-8 w-8 text-red-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 14"
                >
                  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                </svg>
                <p className="text-base md:text-lg font-medium italic">
                  “You did a fabulous job. There were no hitches. Everything was
                  included, and exactly what was promised to us. Thank you again
                  for all your work planning this trip. We will definitely
                  recommend you.”
                </p>
              </Blockquote>
              <figcaption className="flex items-center justify-center mt-4">
                <Avatar
                  rounded
                  className="w-16 h-16 rounded-full self-start mr-4"
                  img="/images/user3.jpg"
                  alt="Director"
                />
                <div className="text-left">
                  <div className="font-bold font-serif text-xl">Director</div>
                  <p className="text-sm">
                    (S & F Financial Services after experiencing customized
                    North East Trip)
                  </p>
                </div>
              </figcaption>
            </figure>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
