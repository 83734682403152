"use client";
import Image from "next/image";

const Ourservices = ({ services, title }) => {
  return (
    <div>
      <section className="py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10">
            <h2 className="font-semibold text-color-blue-600 my-3">{title}</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-stretch justify-between text-center">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white shadow-xl shadow-gray-400/40 hover:shadow-gray-400 p-6 md:p-14 rounded-md"
              >
                <div className="bg-red-600 p-4 rounded-full w-fit m-auto">
                  <Image
                    src={service.icon}
                    className="object-cover w-14 h-14"
                    alt={service.altText}
                    width={48}
                    height={48}
                  />
                </div>
                <h5 className="font-sans font-bold text-color-blue-600 my-4">
                  {service.title}
                </h5>
                <p>{service.description}</p>
                <p>
                  <a
                    href={service.link}
                    className="text-red-600 font-bold text-sm uppercase"
                  >
                    Read More
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ourservices;
